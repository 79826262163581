/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:df99ae0d-0f72-402e-a848-d96dbdc41ddf",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_usR6Mj9ci",
    "aws_user_pools_web_client_id": "5j99ptojas780qq3l9oghk7p9h",
    "oauth": {}
};


export default awsmobile;
